import { type Config } from 'tailwindcss'

export const extendedTheme = {
	colors: {
		border: 'hsl(var(--border))',
		input: {
			DEFAULT: 'hsl(var(--input))',
			invalid: 'hsl(var(--input-invalid))',
		},
		ring: {
			DEFAULT: 'hsl(var(--ring))',
			invalid: 'hsl(var(--foreground-destructive))',
		},
		background: {
			DEFAULT: 'hsl(var(--background))',
			secondary: 'hsl(var(--background-secondary))',
		},
		foreground: {
			DEFAULT: 'hsl(var(--foreground))',
			destructive: 'hsl(var(--foreground-destructive))',
		},
		primary: {
			DEFAULT: 'hsl(var(--primary))',
			foreground: 'hsl(var(--primary-foreground))',
		},
		secondary: {
			DEFAULT: 'hsl(var(--secondary))',
			foreground: 'hsl(var(--secondary-foreground))',
		},
		destructive: {
			DEFAULT: 'hsl(var(--destructive))',
			foreground: 'hsl(var(--destructive-foreground))',
		},
		muted: {
			DEFAULT: 'hsl(var(--muted))',
			foreground: 'hsl(var(--muted-foreground))',
		},
		accent: {
			DEFAULT: 'hsl(var(--accent))',
			foreground: 'hsl(var(--accent-foreground))',
		},
		popover: {
			DEFAULT: 'hsl(var(--popover))',
			foreground: 'hsl(var(--popover-foreground))',
		},
		card: {
			DEFAULT: 'hsl(var(--card))',
			foreground: 'hsl(var(--card-foreground))',
		},
		gain: {
			DEFAULT: 'hsl(var(--gain))',
			foreground: 'hsl(var(--gain-foreground))',
		},
	},
	borderRadius: {
		lg: 'var(--radius)',
		md: 'calc(var(--radius) - 2px)',
		sm: 'calc(var(--radius) - 4px)',
	},
	fontSize: {
		// 1rem = 16px
		/** 80px size / 84px high / bold */
		mega: ['5rem', { lineHeight: '5.25rem', fontWeight: '700' }],
		/** 56px size / 62px high / bold */
		h1: ['3.5rem', { lineHeight: '3.875rem', fontWeight: '700' }],
		/** 40px size / 48px high / bold */
		h2: ['2.5rem', { lineHeight: '3rem', fontWeight: '700' }],
		/** 32px size / 36px high / bold */
		h3: ['2rem', { lineHeight: '2.25rem', fontWeight: '700' }],
		/** 28px size / 36px high / bold */
		h4: ['1.75rem', { lineHeight: '2.25rem', fontWeight: '700' }],
		/** 24px size / 32px high / bold */
		h5: ['1.5rem', { lineHeight: '2rem', fontWeight: '700' }],
		/** 16px size / 20px high / bold */
		h6: ['1rem', { lineHeight: '1.25rem', fontWeight: '700' }],

		/** 32px size / 36px high / normal */
		'body-2xl': ['2rem', { lineHeight: '2.25rem' }],
		/** 28px size / 36px high / normal */
		'body-xl': ['1.75rem', { lineHeight: '2.25rem' }],
		/** 24px size / 32px high / normal */
		'body-lg': ['1.5rem', { lineHeight: '2rem' }],
		/** 20px size / 28px high / normal */
		'body-md': ['1.25rem', { lineHeight: '1.75rem' }],
		/** 16px size / 20px high / normal */
		'body-sm': ['1rem', { lineHeight: '1.25rem' }],
		/** 14px size / 18px high / normal */
		'body-xs': ['0.875rem', { lineHeight: '1.125rem' }],
		/** 12px size / 16px high / normal */
		'body-2xs': ['0.75rem', { lineHeight: '1rem' }],
		'badge-xxs': ['0.6rem', { lineHeight: '0.8rem' }],

		/** 18px size / 24px high / semibold */
		caption: ['1.125rem', { lineHeight: '1.5rem', fontWeight: '600' }],
		/** 12px size / 16px high / bold */
		button: ['0.75rem', { lineHeight: '1rem', fontWeight: '700' }],
	},
	screens: {
		'xs': '390px',
		'720p': {
			raw: '(max-height: 730px), (max-width: 1300px)',
		},
	},
	keyframes: {
		'accordion-down': {
			from: { height: '0' },
			to: { height: 'var(--radix-accordion-content-height)' },
		},
		'accordion-up': {
			from: { height: 'var(--radix-accordion-content-height)' },
			to: { height: '0' },
		},
		slidein: {
			from: {
				opacity: '0',
				transform: 'translateY(-10px)',
			},
			to: {
				opacity: '1',
				transform: 'translateY(0)',
			},
		},
		shimmer: {
			from: {
				backgroundPosition: '0 0',
			},
			to: {
				backgroundPosition: '-200% 0',
			},
		},
		scroll: {
			to: {
				transform: 'translate(calc(-50% - 0.5rem))',
			},
		},
		gradient: {
			to: { backgroundPosition: '200% center' },
		},
		'border-beam': {
			'100%': {
				'offset-distance': '100%',
			},
		},
	},
	animation: {
		'accordion-down': 'accordion-down 0.2s ease-out',
		'accordion-up': 'accordion-up 0.2s ease-out',
		slidein: 'slidein 1s ease var(--slidein-delay, 0) forwards',
		shimmer: 'shimmer 4s linear infinite',
		scroll:
			'scroll var(--animation-duration, 40s) var(--animation-direction, forwards) linear infinite',
		gradient: 'gradient 8s linear infinite',
		marquee: 'marquee 35s linear infinite',
		'border-beam': 'border-beam calc(var(--duration)*1s) infinite linear',
	},
	dropShadow: {
		glow: [
			'0 0px 20px rgba(255,255, 255, 0.35)',
			'0 0px 65px rgba(255, 255,255, 0.2)',
		],
		greenGlow: [
			'0 0px 20px rgba(0,255, 0, 0.35)',
			'0 0px 65px rgba(0, 255,0, 0.2)',
		],
		sunlightGlow: [
			'0 0px 20px rgba(255,247,85, 0.35)',
			'0 0px 65px rgba(255,247,85, 0.2)',
		],
	},
} satisfies Config['theme']
